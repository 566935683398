import React from "react";
import PropTypes from "prop-types";

const PageHero = ({ subtitle, title }) => {
  return (
    <div className="pages">
      <div className="content needs-c">
        <div className="left-right-blog">
          <div className="left-right">
            {subtitle && <p className="cyan-txt">{subtitle}</p>}
            <h1 className="pages-heading pre-line">{title}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

PageHero.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

PageHero.defaultProps = {
  subtitle: "",
};

export default PageHero;
