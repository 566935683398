import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import PageHero from '../components/PageHero';
import HomeCta from '../components/HomeCta';

// eslint-disable-next-line
export const FormPageTemplate = ({
  id,
  description,
  embed,
  contactDetails,
  contactPhone,
  queryValue,
  helmet,
}) => {
  const baseUrl = embed ? embed : 'https://notionforms.io/forms/contact-us-80';
  const queryParam = '564f22ad-77ea-410c-b36d-7531843c9975';
  const url = `${baseUrl}?${queryParam}=${queryValue}`;

  return (
    <section className="section custom-page">
      {helmet || ''}
      <div className="container content container-page">
        <div
          className="flex"
          style={{
            flexDirection: 'column',
            gap: '0 64px',
          }}
        >
          <div className="flex is-full flex-sm-col-reverse" style={{ gap: '64px' }}>
            <div className="is-full">
              <iframe
                title={id}
                style={{ border: 'none', width: '100%' }}
                height="650px"
                src={url}
              />
            </div>
            <div className="is-full full-form">
              {description && (
                <div
                  style={{
                    fontSize: '24px',
                    lineHeight: '1.5',
                    marginBottom: '4rem',
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              {contactDetails && (
                <>
                  <p className="blue-txt">Get in touch</p>
                  <h1>{contactPhone}</h1>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FormPageTemplate.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  embed: PropTypes.string,
  contactDetails: PropTypes.bool,
  contactPhone: PropTypes.string,
  contactAddress: PropTypes.string,
  queryValue: PropTypes.string,
};

const FormPage = ({ data }) => {
  const { markdownRemark: post, contactDetail } = data;

  return (
    <Layout noPadding darkLogo isDisplayGetQuoteBtn={false}>
      <PageHero title={post.frontmatter.subtitle} subtitle={post.frontmatter.title} />
      <FormPageTemplate
        id={post.id}
        description={post.html}
        embed={post.frontmatter.embed}
        contactDetails={post.frontmatter.contact_details}
        contactPhone={contactDetail.frontmatter.phone}
        contactAddress={contactDetail.frontmatter.Address}
        queryValue={post.frontmatter.type}
        helmet={
          <Helmet titleTemplate="%s | Forms">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.excerpt}`} />
          </Helmet>
        }
      />
      <HomeCta />
    </Layout>
  );
};

FormPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default FormPage;

export const pageQuery = graphql`
  query FormPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        embed
        contact_details
        type
      }
    }
    contactDetail: markdownRemark(frontmatter: { templateKey: { eq: "contact-detail" } }) {
      id
      frontmatter {
        phone
        Address
      }
    }
  }
`;
